<template>
  <keep-alive :max="3">
    <router-view :key="$route.fullPath" />
  </keep-alive>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
</style>
