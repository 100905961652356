/* eslint-disable no-unused-vars */
import Vue from "vue";
import { fetchAPI } from "../../utils";

const state = {
  faqs: [],
  nextFaq: {},
  previousFaq: {},
  categories: [],
};

const getters = {
  allFaqs: (state) => state.faqs,
  faqCategories: (state) => state.categories,
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  async getFaqs({ commit }, { thePage, theLimit, category, isLoading }) {
    const loader = isLoading ? Vue.$loading.show() : null;
    try {
      const response = await fetchAPI(
        `${process.env.VUE_APP_API}/api/faq/admin-get?category=${category ||
          ""}&page=${thePage}&limit=${theLimit}`
      );
      const res = await response.json();
      // console.log(res.data);
      const { results, categories } = res.data;
      commit("setFaqs", results);
      // commit('setPagination', { next, previous });
      commit("setCategories", categories);
    } catch (e) {
      Vue.$toast.open({
        message: e,
        type: "error",
      });
    } finally {
      if (loader) loader.hide();
    }
  },
  async saveFaq({ commit }, { formdata }) {
    try {
      const response = await fetchAPI(`${process.env.VUE_APP_API}/api/faq`, {
        method: "POST",
        body: formdata,
      });
      const result = await response.json();
      const { message } = result;
      Vue.$toast.open(message);
      return true;
    } catch (e) {
      Vue.$toast.open({
        message: e,
        type: "error",
      });
      return false;
    }
  },
  async delFaq({ commit }, { id }) {
    const response = await fetchAPI(
      `${process.env.VUE_APP_API}/api/faq/${id}`,
      {
        method: "DELETE",
      }
    );
    if (response.status < 400) {
      location.reload();
    }
  },
};

const mutations = {
  setFaqs: (state, faqs) => (state.faqs = faqs),
  setPagination: (state, { next, previous }) => {
    state.nextFaq = next;
    state.previousFaq = previous;
  },
  setCategories: (state, categories) => (state.categories = categories),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
