import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import NotFound from "../views/NotFound.vue";

import store from "../store/index";
import auth from "./middleware/auth";
import homeRedirect from "./middleware/homeRedirect";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      middleware: [homeRedirect],
    },
  },
  {
    path: "/home",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "corporate_standard" */ "../views/Home.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/email",
    name: "Email",
    component: () =>
      import(/* webpackChunkName: "corporate_standard" */ "../views/Email.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/faq",
    name: "Faq",
    component: () =>
      import(/* webpackChunkName: "corporate_standard" */ "../views/Faq.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: () =>
      import(
        /* webpackChunkName: "corporate_standard" */ "../views/Feedback.vue"
      ),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/abuse",
    name: "Abuse",
    component: () =>
      import(/* webpackChunkName: "corporate_standard" */ "../views/Abuse.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: () =>
      import(/* webpackChunkName: "corporate_standard" */ "../views/Jobs.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/companies",
    name: "Companies",
    component: () =>
      import(
        /* webpackChunkName: "corporate_standard" */ "../views/Companies.vue"
      ),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/quotes",
    name: "Quotes",
    component: () =>
      import(
        /* webpackChunkName: "corporate_standard" */ "../views/Quotes.vue"
      ),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "corporate_standard" */ "../views/Users.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/reports",
    name: "Reports",
    component: () =>
      import(/* webpackChunkName: "corporate_standard" */ "../views/Reports.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/payments",
    name: "Payments",
    component: () =>
      import(
        /* webpackChunkName: "corporate_standard" */ "../views/Payments.vue"
      ),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/support-tickets",
    name: "SupportTickets",
    component: () =>
      import(
        /* webpackChunkName: "corporate_standard" */ "../views/SupportTickets.vue"
      ),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/view-ticket/:id",
    name: "ViewTicket",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "corporate_standard" */ "../views/ViewTicket.vue"
      ),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/payment/user/:id",
    name: "UserPayment",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "corporate_standard" */ "../views/UserPayment.vue"
      ),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/job-applications/:id",
    name: "JobApplication",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "corporate_standard" */ "../views/JobApplication.vue"
      ),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/view-company/:id",
    name: "ViewCompany",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "corporate_standard" */ "../views/ViewCompany.vue"
      ),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/view-company-stats/:id",
    name: "ViewCompanyStats",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "corporate_standard" */ "../views/ViewCompanyStats.vue"
      ),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "*",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store,
  };
  return middleware[0]({
    ...context,
  });
});
export default router;
