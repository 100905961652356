import Vue from 'vue';
import { fetchAPI } from "../../utils";

const state = {
  depts: [],
};

const getters = {
  allDepts: state => state.depts,
};

const actions = {
  async fetchDepts({ commit }) {
    const loader =  Vue.$loading.show();
    try {
      const response = await fetchAPI(process.env.VUE_APP_API +  `/api/department/get`);
      if (response.status < 400 ) {
        const { departments } = await response.json();
        commit('setDepts', departments);
      }
    } catch (e) {
      Vue.$toast.open({ 
        message: e, 
        type: 'error'
      });
    } finally {
      loader.hide();         
    }
  },
  async addDepts({ commit }, { deptname }) {
    const loader =  Vue.$loading.show();
    try {
      const response = await fetchAPI(process.env.VUE_APP_API +  `/api/department/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          department: {
            name: deptname
          }
        })
      });
      if (response.status < 400 ) {
        const { department } = await response.json();
        commit('addToDept', department);
        Vue.$toast.open('Dept Added');
      }
      return true;
    } catch (e) {
      Vue.$toast.open({ 
        message: e, 
        type: 'error'
      });
      return false;
    } finally {
      loader.hide();
    }
  },
  async delDept({ commit }, { id }) {
    const loader =  Vue.$loading.show();
    try {
      const response = await fetchAPI(process.env.VUE_APP_API +  `/api/department/delete/${id}`);
      if (response.status < 400 ) {
        commit('delDepts', id); 
        Vue.$toast.open('Dept Deleted');
      }
    } catch (e) {
      Vue.$toast.open({ 
        message: e, 
        type: 'error'
      });
    } finally {
      loader.hide();         
    }
  },
};

const mutations = {
  setDepts: (state, depts) => state.depts = depts,
  addToDept: (state, dept) => state.depts = [ ...state.depts, dept],
  delDepts: (state, id) => state.depts = state.depts.filter(dept => dept._id !== id),
};

export default {
  state,
  getters,
  actions,
  mutations
}
