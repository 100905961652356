import Vue from "vue";
// import { fetchAPI } from "../../utils";

const state = {
  payments: [],
  nextPayment: {},
  previousPayment: {},
  user: {},
  payment: {},
  count: 0,
};

const getters = {
  allPayments: (state) => state.payments,
  nextPayment: (state) => state.nextPayment,
  previousPayment: (state) => state.previousPayment,
  pUser: (state) => state.user,
  payment: (state) => state.payment,
};

const actions = {
  async fetchPayments(
    { commit },
    { thePage, theLimit, date, q, isLoading, endDate }
  ) {
    const loader = isLoading ? Vue.$loading.show() : null;
    try {
      const response = await fetch(
        `${process.env.VUE_APP_API}/api/payments?q=${q}&page=${thePage}&limit=${theLimit}&date=${date}&endDate=${endDate}`
      );
      const payments = await response.json();
      const { next, previous, count, results } = payments.data;
      commit("setPagination", { next, previous });
      commit("setPayments", results);
      commit("setCount", count);
    } catch (e) {
      console.log(e);
      Vue.$toast.open({
        message: e.message,
        type: "error",
      });
    } finally {
      if (loader) loader.hide();
    }
  },
  async fetchPaymentsForUser({ commit }, { user_id }) {
    // const loader = Vue.$loading.show();
    try {
      const response = await fetch(
        `${process.env.VUE_APP_API}/api/payments/${user_id}`
      );

      const theuser = await response.json();
      const { user, user_payment } = theuser.data;
      commit("setCUser", user);
      commit("setPayment", user_payment);
    } catch (e) {
      console.log(e);
      Vue.$toast.open({
        message: e.message,
        type: "error",
      });
    } finally {
      // loader.hide();
    }
  },
};

const mutations = {
  setPayments: (state, payments) => (state.payments = payments),
  setPagination: (state, { next, previous }) => {
    state.previousPayment = previous;
    state.nextPayment = next;
  },
  setCount: (state, count) => (state.count = count),
  setCUser: (state, user) => (state.user = user),
  setPayment: (state, user_payment) => (state.payment = user_payment),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
