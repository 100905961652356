import Vue from 'vue';
import { fetchAPI } from "../../utils";

const state = {
  jobs: [],
};

const getters = {
  allJobs: state => state.jobs,
};

const actions = {
  async fetchJobs({ commit }) {
    const loader =  Vue.$loading.show();
    try {
      const response = await fetchAPI(process.env.VUE_APP_API +  `/api/job/get-all`);
      if (response.status < 400 ) {
        const { jobs } = await response.json();
        commit('setJobs', jobs);
      }
    } catch (e) {
      Vue.$toast.open({ 
        message: e, 
        type: 'error'
      });
    } finally {
      loader.hide();         
    }
  },
  async addJobs({ commit }, theJob ) {
    const loader =  Vue.$loading.show();
    try {
      const response = await fetchAPI(process.env.VUE_APP_API +  `/api/job/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          job: {
            ...theJob
          }
        })
      });
      if (response.status < 400 ) {
        const { job } = await response.json();
        commit('addToJob', job);
        console.log(job);
        Vue.$toast.open('Job Added');
      }
      return true;
    } catch (e) {
      Vue.$toast.open({ 
        message: e, 
        type: 'error'
      });
      return false;
    } finally {
      loader.hide();
    }
  }
};

const mutations = {
  setJobs: (state, jobs) => state.jobs = jobs,
  addToJob: (state, job) => state.jobs = [ ...state.jobs, job]
};

export default {
  state,
  getters,
  actions,
  mutations
}
