import Vue from "vue";
import { fetchAPI } from "../../utils";

const state = {
  users: [],
  nextUser: {},
  previousUser: {},
  userCount: "",
  userStat: {},
};

const getters = {
  allUsers: (state) => state.users,
  nextUser: (state) => state.nextQuote,
  previousUser: (state) => state.previousQuote,
  userCount: (state) => state.userCount,
  userStat: (state) => state.userStat,
};

const actions = {
  async fetchUsers({ commit }, { thePage, theLimit }) {
    const loader = Vue.$loading.show();
    try {
      const response = await fetchAPI(
        process.env.VUE_APP_API + `/api/user?page=${thePage}&limit=${theLimit}`
      );
      const users = await response.json();
      const { next, previous, results, count } = users.data;
      commit("setPagination", { next, previous });
      commit("setUsers", results);
      commit("setUserCount", count);
    } catch (e) {
      Vue.$toast.open({
        message: e,
        type: "error",
      });
    } finally {
      loader.hide();
    }
  },
  async searchUsers(
    { commit },
    {
      email,
      thePage,
      theLimit,
      the_account_type,
      date,
      isLoading,
      typing,
      endDate,
      searchBy,
    }
  ) {
    const loader = isLoading || !typing ? Vue.$loading.show() : null;
    try {
      const response = await fetchAPI(
        process.env.VUE_APP_API +
          `/api/user/search?the_account_type=${the_account_type}&email=${email}&page=${thePage}&limit=${theLimit}&date=${date}&endDate=${endDate}&searchBy=${searchBy}`
      );
      const { data, count } = await response.json();
      const { next, previous, results } = data;
      commit("setPagination", { next, previous });
      commit("setUsers", results);
      commit("setUserCount", count);
    } catch (e) {
      Vue.$toast.open({
        message: e,
        type: "error",
      });
    } finally {
      if (loader) loader.hide();
    }
  },

  async fetchUserStats({ commit }, { user_id }) {
    // const loader = Vue.$loading.show();
    try {
      const response = await fetch(
        `${process.env.VUE_APP_API}/api/user/statistics/${user_id}`
      );

      const data = await response.json();
      const { filesCount, folderCount, taskCount, projectCount } = data;
      commit("setCUserStat", {
        filesCount,
        folderCount,
        taskCount,
        projectCount,
      });
    } catch (e) {
      console.log(e);
      Vue.$toast.open({
        message: e.message,
        type: "error",
      });
    } finally {
      // loader.hide();
    }
  },
};

const mutations = {
  setUsers: (state, users) => (state.users = users),
  setUserCount: (state, count) => (state.userCount = count),
  setPagination: (state, { next, previous }) => {
    state.previousUser = previous;
    state.nextUser = next;
  },
  setCUserStat: (state, stat) => (state.userStat = stat),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
