import Vue from "vue";
import { fetchAPI } from "../../utils";

const state = {
  abuses: [],
  nextAbuse: {},
  previousAbuse: {},
};

const getters = {
  allAbuses: (state) => state.abuses,
  nextAbuse: (state) => state.nextAbuse,
  previousAbuse: (state) => state.previousAbuse,
};

const actions = {
  async fetchAbuses(
    { commit },
    { thePage, theLimit, q, date, typing, isLoading, endDate }
  ) {
    const loader = !typing || isLoading ? Vue.$loading.show() : null;
    try {
      const response = await fetchAPI(
        process.env.VUE_APP_API +
          `/api/abuses?page=${thePage}&limit=${theLimit}&q=${q}&date=${date}&endDate=${endDate}`
      );
      const abuses = await response.json();
      const { next, previous, data } = abuses;
      commit("setPagination", { next, previous });
      commit("setAbuses", data);
    } catch (e) {
      Vue.$toast.open({
        message: e,
        type: "error",
      });
    } finally {
      if (loader) loader.hide();
    }
  },
};

const mutations = {
  setAbuses: (state, abuses) => (state.abuses = abuses),
  setPagination: (state, { next, previous }) => {
    state.previousAbuse = previous;
    state.nextAbuse = next;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
