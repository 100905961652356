import Vue from "vue";
import { fetchAPI } from "../../utils";

const state = {
  companies: [],
  company: {},
  companyUsers: [],
  nextCompanyUsers: {},
  previousCompanyUsers: {},
  nextCompany: {},
  previousCompany: {},
  companyCount: null,
  userCount: null,
};

const getters = {
  allCompanies: (state) => state.companies,
  nextCompany: (state) => state.nextCompany,
  previousCompany: (state) => state.previousCompany,
  companyUsers: (state) => state.companyUsers,
  company: (state) => state.company,
  companyUserCount: (state) => state.userCount,
  companyCount: (state) => state.companyCount,
};

const actions = {
  async fetchCompanies({ commit }, { thePage, theLimit }) {
    const loader = Vue.$loading.show();
    try {
      const response = await fetchAPI(
        process.env.VUE_APP_API +
          `/api/company?page=${thePage}&limit=${theLimit}`
      );
      const companies = await response.json();
      const { next, previous, results, count } = companies.data;
      commit("setPagination", { next, previous });
      commit("setCompanies", results);
      commit("setCompanyCount", count);
    } catch (e) {
      Vue.$toast.open({
        message: e,
        type: "error",
      });
    } finally {
      loader.hide();
    }
  },
  async searchCompanies(
    { commit },
    { name, thePage, theLimit, isLoading, date, endDate }
  ) {
    const loader = isLoading ? Vue.$loading.show() : null;
    try {
      const response = await fetchAPI(
        process.env.VUE_APP_API +
          `/api/company/search?name=${name}&page=${thePage}&limit=${theLimit}&date=${date}&endDate=${endDate}`
      );
      const companies = await response.json();
      const { next, previous, results, count } = companies.data;
      commit("setPagination", { next, previous });
      commit("setCompanies", results);
      commit("setCompanyCount", count);
    } catch (e) {
      Vue.$toast.open({
        message: e,
        type: "error",
      });
    } finally {
      if (loader) loader.hide();
    }
  },
  async getCompany(
    { commit },
    { id, thePage, theLimit, isLoading, q, searchBy }
  ) {
    const loader = isLoading ? Vue.$loading.show() : null;

    try {
      const response = await fetchAPI(
        process.env.VUE_APP_API +
          `/api/company/users/${id}?page=${thePage}&limit=${theLimit}&q=${q}&searchBy=${searchBy}`
      );
      const companyUser = await response.json();
      const { next, userCount, previous, results, company } = companyUser.data;
      commit("setCompany", company);
      commit("setCompanyUsers", results);
      commit("setUserCount", userCount);
      commit("setCompanyUsersPagination", { next, previous });
    } catch (e) {
      Vue.$toast.open({
        message: e,
        type: "error",
      });
    } finally {
      if (loader) loader.hide();
    }
  },
};

const mutations = {
  setUserCount: (state, count) => (state.userCount = count),
  setCompanies: (state, companies) => (state.companies = companies),
  setPagination: (state, { next, previous }) => {
    state.previousCompany = previous;
    state.nextCompany = next;
  },
  setCompany: (state, company) => (state.company = company),
  setCompanyUsers: (state, results) => (state.companyUsers = results),
  setCompanyUsersPagination: (state, { next, previous }) => {
    state.previousCompanyUsers = previous;
    state.nextCompanyUsers = next;
  },
  setCompanyCount: (state, count) => (state.companyCount = count),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
