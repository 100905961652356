<template>
  <div class="father bg-secondary">
    <div class="child bg-white p-4 rounded shadow">
      <div class="text-center">
        <a href="http://fexspace.com"
          ><img src="https://cloud.fexspace.com/logo-small-blue.png"
        /></a>
      </div>
      <p class="text-center font-weight-bold">Administrator Login</p>
      <form @submit.prevent="onSubmit">
        <small :style="{ color: 'red' }">{{ errorMsg }}</small>
        <div class="form-group">
          <label>Email</label>
          <input
            v-model="form.email"
            type="email"
            class="form-control bg-light border border-light"
            placeholder="Email"
          />
        </div>
        <div class="form-group">
          <label>Password</label>
          <input
            v-model="form.password"
            type="password"
            class="form-control bg-light border border-light"
            placeholder="Password"
          />
        </div>
        <button
          type="submit"
          ref="submit"
          class="w-100 btn btn-primary float-right"
        >
          {{ btnText }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      errorMsg: "",
      btnText: "Login",
    };
  },
  methods: {
    ...mapActions(["saveUser"]),
    async onSubmit() {
      try {
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.form.email)) {
          this.errorMsg = "please Enter a valid email address";
          throw "please Enter a valid email address";
        }
        if (this.form.password === "") {
          this.errorMsg = "Password is required";
          throw "Password is required";
        }

        this.btnText = "Sending...";
        this.$refs.submit.disabled = true;

        const response = await fetch(
          process.env.VUE_APP_API + "/api/auth/email/login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: this.form.email,
              password: this.form.password,
            }),
            redirect: "follow",
          }
        );

        if (response.status < 400) {
          const { user, token } = await response.json();
          this.saveUser({
            user,
            token,
          });
          // return this.$router.push({ path: "/home" });
          return (location.href = "/home");
        } else if (response.status == 401) {
          this.errorMsg = "Invalid Email or password";
        } else {
          this.$toast.open({
            message: "Check your credentials and try again",
            type: "error",
          });
        }
      } catch (e) {
        this.$toast.open({
          message: e,
          type: "error",
        });
      } finally {
        this.btnText = "Submit";
        this.$refs.submit.disabled = false;
      }
    },
  },
  computed: mapGetters(["authUser"]),
  mounted() {
    /*const user = this.authUser;
    console.log(user);
    if (Object.keys(user).length) {
      this.$router.push({ path: "/home" });
    }
    */
  },
};
</script>

<style scoped>
.father {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.child {
  margin: 1rem;
  position: absolute;
  width: 100%;
  max-width: calc(100vw - 1.5rem);
  height: fit-content;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@media screen and (min-width: 760px) {
  .child {
    position: absolute;
    width: 30vw;
    /* height: 65vh; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
</style>