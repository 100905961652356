import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";
import VueToast from "vue-toast-notification";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Loading from "vue-loading-overlay";
import vueDebounce from "vue-debounce";
import Clipboard from "v-clipboard";
// import VueScrollTo from 'vue-scrollto';
import "vue-loading-overlay/dist/vue-loading.css";
import "../public/custom.scss";
import "vue-toast-notification/dist/theme-default.css";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueToast);
Vue.use(Loading);
Vue.use(vueDebounce, {
  listenTo: ["input", "keyup"],
  defaultTime: "1000ms",
});

Vue.use(Clipboard);
// Vue.use(VueScrollTo);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
