import Vue from "vue";
import { fetchAPI } from "../../utils";

const state = {
  quotes: [],
  nextQuote: {},
  previousQuote: {},
};

const getters = {
  allQuotes: (state) => state.quotes,
  nextQuote: (state) => state.nextQuote,
  previousQuote: (state) => state.previousQuote,
};

const actions = {
  async fetchQuotes(
    { commit },
    { thePage, theLimit, q, date, typing, isLoading, endDate }
  ) {
    const loader = !typing || isLoading ? Vue.$loading.show() : null;
    try {
      const response = await fetchAPI(
        `${process.env.VUE_APP_API}/api/quotes?page=${thePage}&limit=${theLimit}&q=${q}&date=${date}&endDate=${endDate}`
      );
      const quotes = await response.json();
      const { next, previous, data } = quotes;
      commit("setPagination", { next, previous });
      commit("setQuotes", data);
    } catch (e) {
      console.log(e);
      Vue.$toast.open({
        message: e.message,
        type: "error",
      });
    } finally {
      if (loader) loader.hide();
    }
  },
};

const mutations = {
  setQuotes: (state, quotes) => (state.quotes = quotes),
  setPagination: (state, { next, previous }) => {
    state.previousQuote = previous;
    state.nextQuote = next;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
