import Vue from "vue";
import { fetchAPI } from "../../utils";

const state = {
  reports: [],
  nextReport: {},
  previousReport: {},
};

const getters = {
  allReports: (state) => state.reports,
  nextReport: (state) => state.nextReport,
  previousReport: (state) => state.previousReport,
};

const actions = {
  async fetchReports(
    { commit },
    { thePage, theLimit, option, date, typing, isLoading, endDate }
  ) {
    const loader = !typing || isLoading ? Vue.$loading.show() : null;
    try {
      const response = await fetchAPI(
        `${process.env.VUE_APP_API}/api/reports?page=${thePage}&limit=${theLimit}&option=${option}&date=${date}&endDate=${endDate}`
      );
      const reports = await response.json();
      const { next, previous, data } = reports;
      commit("setPagination", { next, previous });
      commit("setReports", data);
    } catch (e) {
      console.log(e);
      Vue.$toast.open({
        message: e.message,
        type: "error",
      });
    } finally {
      if (loader) loader.hide();
    }
  },
};

const mutations = {
  setReports: (state, reports) => (state.reports = reports),
  setPagination: (state, { next, previous }) => {
    state.previousReport = previous;
    state.nextReport = next;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
