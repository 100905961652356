import Vue from "vue";
import connection from "../../connect/index";
import { fetchAPI } from "../../utils";

const state = {
  tickets: [],
  ticket: {},
  ticketNotification: [],
  nextTicket: {},
  previousTicket: {},
};

const getters = {
  allTickets: (state) => state.tickets,
  singleTicket: (state) => state.ticket,
  ticketNotification: (state) => state.ticketNotification,
  nextTicket: (state) => state.next,
  previousTicket: (state) => state.previous,
};

const actions = {
  async fetchTickets({ commit }, { thePage, theLimit }) {
    // console.log(theLimit);
    const loader = Vue.$loading.show();
    try {
      const response = await fetchAPI(
        process.env.VUE_APP_API +
          `/api/tickets?page=${thePage}limit=${theLimit}`
      );
      const tickets = await response.json();
      const { next, previous } = tickets;
      commit("setPagination", { next, previous });
      commit("setTickets", tickets.data);
    } catch (e) {
      Vue.$toast.open({
        message: e,
        type: "error",
      });
    } finally {
      loader.hide();
    }
  },
  async searchTickets(
    { commit },
    { email, thePage, theLimit, date, support_type, typing, endDate, searchBy }
  ) {
    const loader = !typing ? Vue.$loading.show() : null;
    try {
      const response = await fetchAPI(
        process.env.VUE_APP_API +
          `/api/tickets/search?email=${email}&date=${date}&page=${thePage}&limit=${theLimit}&support_type=${support_type}&endDate=${endDate}&searchBy=${searchBy}`
      );
      const tickets = await response.json();
      const { next, previous } = tickets;
      commit("setPagination", { next, previous });
      commit("setTickets", tickets.data);
    } catch (e) {
      Vue.$toast.open({
        message: e,
        type: "error",
      });
    } finally {
      if (loader) loader.hide();
    }
  },
  async replyMail({ commit }, { id, formData }) {
    try {
      const response = await fetchAPI(
        process.env.VUE_APP_API +
          `/api/tickets/admin-reply/${encodeURIComponent(id)}`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (response.status == 200) {
        const { data, message } = await response.json();
        commit("setTicketMessage", data);
        Vue.$toast.open(message);
        return { data, message, success: true };
      } else {
        Vue.$toast.open({
          message: "Unable to Send reply.",
          type: "error",
        });
        return { success: false };
      }
    } catch (e) {
      console.log(e);
      Vue.$toast.open({
        message: e.message,
        type: "error",
      });
      return { success: false };
    }
  },
  async getSingleTicket({ commit }, id) {
    const loader = Vue.$loading.show();
    try {
      const response = await fetchAPI(
        process.env.VUE_APP_API +
          `/api/tickets/view-ticket/${encodeURIComponent(id)}`
      );
      const ticket = await response.json();
      commit("setTicket", ticket.data);
      commit("removeNotification", ticket.data.ticket_id);
    } catch (e) {
      Vue.$toast.open({
        message: e.message,
        type: "error",
      });
    } finally {
      loader.hide();
    }
  },
  async ticketsWithUnread({ commit }) {
    try {
      const response = await fetchAPI(
        process.env.VUE_APP_API + "/api/tickets/latest"
      );
      const tickets = await response.json();
      commit("setNotifications", tickets.data);
    } catch (e) {
      Vue.$toast.open({
        message: e,
        type: "error",
      });
    }
  },
  async changeTicketStatus({ commit }, { ticket_id, status }) {
    const loader = Vue.$loading.show();
    try {
      const response = await fetchAPI(
        process.env.VUE_APP_API +
          `/api/tickets/change-status/${encodeURIComponent(
            ticket_id
          )}?status=${status}`,
        {
          method: "POST",
        }
      );
      const result = await response.json();
      commit("setStatus", status);
      Vue.$toast.open(result.message);
    } catch (e) {
      Vue.$toast.open({
        message: e,
        type: "error",
      });
    } finally {
      loader.hide();
    }
  },
};

const mutations = {
  setTickets: (state, tickets) => (state.tickets = tickets),
  setTicket: (state, ticket) => (state.ticket = ticket),
  setTicketMessage: (state, ticket) => {
    const theStateTicket = state.ticket;
    theStateTicket.email_messages.push(ticket);
    state.ticket = theStateTicket;
  },
  setNotifications: (state, ticket) => {
    state.ticketNotification = [...state.ticketNotification, ...ticket];
  },
  removeNotification: (state, ticket_id) => {
    state.ticketNotification = state.ticketNotification.filter(
      (ticket) => ticket.ticket_id !== ticket_id
    );
  },
  setPagination: (state, { next, previous }) => {
    state.previous = previous;
    state.next = next;
  },
  setStatus: (state, status) => (state.ticket.status = status),
};

connection.on("newMail", ({ ticket_id, business_email }) => {
  // state.unreadCount = state.unreadCount++;
  state.ticketNotification = [
    { ticket_id, business_email },
    ...state.ticketNotification,
  ];
});

export default {
  state,
  getters,
  actions,
  mutations,
};
