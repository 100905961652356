import Vue from "vue";
import { fetchAPI } from "../../utils";

const state = {
  feedbacks: [],
  nextFeedback: {},
  previousFeedback: {},
  average: {},
};

const getters = {
  allFeedbacks: (state) => state.feedbacks,
  nextFeedback: (state) => state.nextFeedback,
  previousFeedback: (state) => state.previousFeedback,
  feedbackAverage: (state) => state.average,
};

const actions = {
  async fetchFeedbacks({ commit }, { thePage, theLimit, date, endDate }) {
    const loader = Vue.$loading.show();
    try {
      const response = await fetchAPI(
        process.env.VUE_APP_API +
          `/api/feedback?page=${thePage}&limit=${theLimit}&date=${date}&endDate=${endDate}`
      );
      const feedbacks = await response.json();
      const { next, previous, results, average } = feedbacks.data;
      commit("setPagination", { next, previous });
      commit("setFeedbacks", results);
      commit("setAverage", average);
    } catch (e) {
      Vue.$toast.open({
        message: e,
        type: "error",
      });
    } finally {
      loader.hide();
    }
  },
};

const mutations = {
  setFeedbacks: (state, feedbacks) => (state.feedbacks = feedbacks),
  setPagination: (state, { next, previous }) => {
    state.previousFeedback = previous;
    state.nextFeedback = next;
  },
  setAverage: (state, average) => (state.average = average),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
