const state = {
  authUser: {},
  loggedIn: false
}

const getters = {
  authUser: state => state.authUser = JSON.parse(localStorage.getItem('authUser')),
  loggedIn: state => state.loggedIn = JSON.parse(localStorage.getItem('loggedIn'))
}

const actions = {
  async saveUser({ commit }, { user, token }) {
    localStorage.setItem('token', JSON.stringify(token));
    commit('setAuthUser', user);
    commit('setLoggedIn', true);
  },
}

const mutations = {
  setAuthUser: (state, user) => {
    localStorage.setItem('authUser', JSON.stringify(user));
    state.authUser = JSON.parse(localStorage.getItem('authUser'));
  },
  setLoggedIn: (state, value) => {
    localStorage.setItem('loggedIn', JSON.stringify(value));
    state.loggedIn = JSON.parse(localStorage.getItem('loggedIn'));
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}