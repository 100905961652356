<template>
  <div class="text-center p-5 mt-5">
    <p class="display-1">404</p>
    <p>Page Not Found</p>
    <router-link :to="{ name: 'SupportTickets'}">Go To Tickets</router-link>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>