import Vuex from "vuex";
import Vue from "vue";
import tickets from "./modules/tickets";
import authUser from "./modules/authuser";
import quotes from "./modules/quotes";
import reports from "./modules/reports";
import abuses from "./modules/abuses";
import feedbacks from "./modules/feedbacks";
import users from "./modules/users";
import companies from "./modules/companies";
import faq from "./modules/faqs";
import depts from "./modules/depts";
import jobs from "./modules/jobs";
import payments from "./modules/payments";
import nav from "./modules/nav";

// load Vuex
Vue.use(Vuex);

// create store
export default new Vuex.Store({
  modules: {
    tickets,
    authUser,
    quotes,
    abuses,
    feedbacks,
    users,
    companies,
    faq,
    depts,
    jobs,
    payments,
    nav,
    reports
  },
});
