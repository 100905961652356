import io from "socket.io-client";
// import Vue from "vue";

let socket;
function connection() {
  if (typeof socket !== "undefined") {
    return socket;
  }

  socket = io(process.env.VUE_APP_API, { transports: ["websocket"] });

  socket.on("disconnect", (error) => {
    console.log(error);
    /*Vue.$toast.open({ 
      message: error, 
      type: 'error'
    });
    */
  });

  return socket;
}

export default connection();
