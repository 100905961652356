// import Vue from 'vue';

async function fetchAPI(url, opt = {}) {
  const response = await fetch(url, {
    ...opt,
    ...{
      headers: {
        ...(opt.headers || {}),
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        "Access-Control-Allow-Origin": "*",
      },
    },
  });
  if (response.status === 401) {
    localStorage.clear();
    location.replace("/login");
  }
  return response;
}

function truncate(string, limit) {
  return string.length > limit ? string.substring(0, limit) + "..." : string;
}

export {
  fetchAPI,
  truncate,
  // unauthorized
};
